@media (max-width: 768px) {
  .navbar {
    justify-content: space-around !important;
  }
  .MuiListItemIcon-root {
    min-width: 42px !important;
  }
  .MuiDrawer-paper img {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .slider .carousel-caption {
    top: 18%;
    height: unset;
    width: 6rem;
    padding-top: 20px;
    padding-left: 26px;
    padding-bottom: 20px;
  }
  .slider h1,
  .slider h2 {
    font-size: 16px;
    font-weight: 400;
  }
  .slider .carousel-indicators {
    top: 15%;
  }
  .slider .carousel-indicators [data-bs-target] {
    width: 7px;
    height: 7px;
  }
  .slider .carousel-indicators .active {
    width: 12px !important;
    height: 12px !important;
  }
  .entry {
    padding-top: 30px;
  }
  .info h4 {
    margin-top: -56px;
    margin-left: 3px;
    font-weight: 600;
    font-size: 1.1rem;
  }
  .info h4 span {
    font-size: 6rem;
  }
  section h2 {
    margin-bottom: 9px;
    font-size: 14px;
  }
  section p {
    font-size: 15px;
    line-height: 28px;
  }
  .btn-primary {
    margin-top: unset;
  }
  .benefits img {
    padding: 6px;
  }
  .benefits h4,
  .benefits p,
  .benefits a {
    margin-left: 66px;
  }
  .benefits h4 {
    font-size: 16px;
  }
  .benefits p {
    line-height: 26px;
    font-size: 14px;
    margin-bottom: 2px;
  }
  .benefits a {
    font-size: 14px;
  }
  section h3 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
  }
  .touch h2 {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
  }
  .bgc-2 .container {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .testimonial {
    padding: 22px;
  }
  .testimonial p {
    font-size: 14px;
    line-height: 24px;
  }
  .testimonial h4 {
    font-size: 14px;
  }
  .testimonials .slick-next {
    right: 40%;
  }
  .testimonials .slick-prev {
    left: 40%;
  }
  .mlogo {
    margin-left: -25px;
  }
  #footer {
    padding-top: 28px;
  }
  .copyright,
  #footer .links {
    text-align: center;
  }
  #footer {
    padding-bottom: 60px;
  }
  .breadcrumbs {
    padding: unset;
    padding-bottom: 14px;
    padding-top: 42px;
  }
  .breadcrumbs h1 {
    font: normal normal 500 22px/0px Poppins;
  }
  .breadcrumbs ul li a,
  .breadcrumbs ul li + li::before,
  .breadcrumbs ul li + li {
    font: normal normal 500 12px/36px Poppins;
  }
  .des-2 {
    width: 22%;
    top: -26px;
    left: 0px;
  }
  .css-jn8x8h {
    left: unset !important;
    -webkit-transform: translate(0%, -50%) !important;
    -moz-transform: translate(0%, -50%) !important;
    -ms-transform: translate(0%, -50%) !important;
    transform: translate(0%, -50%) !important;
  }
  .contact-form {
    padding: 18px;
    padding-top: 26px;
  }
  .contact-info .icon.mt-unset {
    margin-top: 40px !important;
  }
}
