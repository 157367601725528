@font-face {
  font-family: Impact;
  src: url("../fonts/impact.ttf") format("ttf");
}

/* General */

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
}

a {
  color: #0064b1;
  text-decoration: none;
}

section h2 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #0064b1;
  text-transform: uppercase;
}

section h3 {
  margin-bottom: 45px;
  font-size: 35px;
  line-height: 50px;
  font-weight: 700;
  color: #131d3b;
  text-transform: capitalize;
  opacity: 0.8;
  position: relative;
}

section h3::after {
  display: block;
  position: absolute;
  width: 5.625rem;
  height: 0.5rem;
  content: "";
  background: linear-gradient(
    to right,
    #ffffff 0,
    #ffffff 10%,
    #ffc942 100%
  ) !important;
  border-radius: 10px;
  border: 2px solid #ffc942;
}

section h3.type-2::after {
  left: 50%;
  margin-left: -45px;
}

section p {
  text-align: justify;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  color: #131d3b;
  opacity: 0.8;
}

.section-bg {
  background-color: #f9f9fd;
}

.entry {
  padding-top: 80px;
}

.cmt-1 {
  margin-top: -52px;
}

.pb-unset {
  padding-bottom: unset;
}

.btn-primary {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 7px;
  background-color: #0064b1;
  border-color: #0064b1;
  color: #fff;
}

.btn-secondary {
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
  border-radius: 7px;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #131d3b;
}

.btn-primary:hover {
  background-color: #0064b1;
  border-color: #0064b1;
  box-shadow: 0px 10px 30px #1264b14b;
}

.mt-unset {
  margin-top: unset !important;
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.MuiTypography-root {
  color: #000;
  font-weight: 500 !important;
}

.header {
  background-color: #0064b1;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fdc83b;
}

.navbar-expand-lg .navbar-nav .nav-link {
  margin-left: 40px;
}

.navbar-expand-lg .navbar-nav .nav-link:first-child {
  margin-left: unset;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #ffffff;
  border-bottom: 2px solid #ffcc2f;
}

.breadcrumbs {
  text-align: center;
  padding: 96px 0;
  min-height: 100%;
  background: linear-gradient(
      111deg,
      rgb(34 32 30 / 20%) 60%,
      rgb(34 32 30 / 20%) 60%,
      rgb(34 32 30 / 20%) 60%
    ),
    url(../images/conmix-infra-ready-mixed-concrete.png);
  background-size: cover;
  background-position: center;
}

.breadcrumbs h1 {
  font: normal normal bold 30px/46px Poppins;
  color: #ffffff;
  text-transform: capitalize;
}

.breadcrumbs ul {
  padding-left: unset;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}

.breadcrumbs ul li a {
  font: normal normal 600 14px/40px Poppins;
  color: #fdc83b;
  text-transform: uppercase;
}

.breadcrumbs ul li a:hover {
  color: #03abee !important;
}

.breadcrumbs ul li + li {
  padding-left: 10px;
  font: normal normal 600 14px/40px Poppins;
  color: #fdc83b;
  text-transform: uppercase;
}

.breadcrumbs ul li + li::before {
  display: inline-block;
  padding-right: 10px;
  content: "/";
  font: normal normal 600 14px/40px Poppins;
  color: #fdc83b;
  text-transform: uppercase;
}

.slider h1,
.slider h2 {
  text-align: left;
  font-size: 60px;
  color: #ffffff;
}

.slider .carousel-caption {
  top: 30%;
  left: 15%;
  white-space: nowrap;
  background: linear-gradient(
      111deg,
      rgb(255 204 47 / 80%) 60%,
      rgb(255 204 47 / 80%) 60%,
      rgb(255 204 47 / 80%) 60%
    ),
    url(../images/about-conmixinfra.png);
  background-position: top right;
  bottom: unset;
  color: #fff;
  width: 22rem;
  height: 22rem;
  border-radius: 50%;
  padding-top: 100px;
  padding-left: 100px;
  padding-bottom: 100px;
}

.slider .carousel-indicators .active {
  width: 15px !important;
  height: 15px !important;
  background-color: #fff !important;
}

.slider .carousel-indicators {
  position: absolute;
  right: unset;
  bottom: 0;
  left: unset;
  z-index: 2;
  display: unset;
  justify-content: unset;
  padding: unset;
  margin-right: unset;
  margin-bottom: unset;
  margin-left: 5%;
  list-style: none;
  top: 46%;
}

.slider .carousel-indicators [data-bs-target] {
  border-radius: 50%;
  display: block;
  margin: -7px;
  box-sizing: content-box;
  /* flex: 0 1 auto; */
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 0px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #f9d154;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity 0.6s ease;
}

.bg-1 {
  background-image: url(../images/bg-1.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
  padding-bottom: unset;
}

.bg-2 {
  padding: unset;
  background-image: url(../images/bg-2.png);
  background-position: top center;
  background-repeat: no-repeat;
}

.bgc-2 {
  background-color: #ffcb2fe8;
}

.bgc-2 .container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.touch h2 {
  font-size: 35px;
  font-weight: 700;
  color: #1264b1;
  text-transform: capitalize;
}

.benefits {
  margin-top: 25px;
  padding: 22px;
  background-color: #ffffff;
  box-shadow: 0px 10px 30px #7b8ecb29;
}

marquee {
  margin-bottom: -4px;
}

.benefits img {
  float: left;
  border-radius: 50%;
  padding: 20px;
  background-color: #f9f9fd;
}

.benefits h4 {
  margin-left: 90px;
  font-size: 18px;
  font-weight: 600;
  color: #131d3b;
  text-transform: capitalize;
  opacity: 0.8;
}

.benefits p {
  margin-left: 90px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #131d3b;
  opacity: 0.8;
}

.benefits a {
  margin-left: 90px;
  font-size: 16px;
  font-weight: 500;
  color: #1264b1;
}

.team {
  text-align: center;
  position: relative;
}

.team img.design {
  position: absolute;
  right: 80px;
}

.team img.profile {
  border-radius: 50%;
  margin-bottom: 25px;
}

.team h4 {
  font-size: 20px;
  font-weight: 600;
  color: #131d3b;
  opacity: 0.8;
}

.team p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #131d3b;
  opacity: 0.8;
}

.des-2 {
  top: -50px;
  left: -75px;
  position: absolute;
}

#footer {
  padding-top: 45px;
  padding-bottom: 1px;
  background-color: #0064b1;
}

#footer svg {
  color: #fff;
  float: left;
  margin-right: 5px;
}

#footer .social ul {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}

#footer .social ul li svg {
  margin: 5px;
  font-size: 40px;
  padding: 8px;
  border-radius: 50%;
  border: 2px solid #ffcc2f;
  color: #ffcc2f;
}

#footer h5 {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 600;
  color: #ffcc2f;
  text-transform: uppercase;
}

#footer h5::after {
  display: inline-block;
  width: 2rem;
  height: 0.2rem;
  margin-left: 8px;
  margin-bottom: 4px;
  content: "";
  background-color: #ffc942 !important;
  border-radius: 10px;
  border: 1px solid #ffc942;
}

#footer p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding-left: 30px;
}

#footer ul {
  list-style: none;
  padding-left: unset;
}

#footer ul li a,
#footer a {
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

#footer ul li a:hover,
#footer a:hover {
  color: #ffc942;
}

.copyright p,
.copyright a {
  padding-left: unset !important;
  color: #fff;
}

#footer .links {
  text-align: right;
}

.testimonials svg {
  bottom: 0;
  color: #000;
}

.testimonials svg:hover {
  color: #ffc942;
}

.testimonials .slick-prev,
.testimonials .slick-next {
  top: unset;
  bottom: -35px;
}

.testimonials .slick-prev {
  left: 47%;
}

.testimonials .slick-next {
  right: 47%;
}

.testimonial {
  margin: 25px;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 10px 10px 20px #7b8ecb29;
}

.testimonial .slick-slide {
  position: relative;
}

.testimonial svg {
  margin-top: -12%;
  border-radius: 50%;
  z-index: 2;
  background-color: #ffc942;
  color: #fff;
  font-size: 50px;
}

.testimonial svg:hover {
  background-color: #ffc942;
  color: #fff;
}

.testimonial p {
  font-size: 16px;
  line-height: 35px;
  color: #131d3b;
  opacity: 0.8;
}

.testimonial h4 {
  font-size: 18px;
  color: #0064b1;
  text-transform: capitalize;
}

.contact-info {
  text-align: center;
}

.contact-info svg {
  margin: 0 10px 12px 10px;
  color: #0064b1;
}

.contact-info h4 {
  font: normal normal 600 18px/25px Poppins;
  color: #131d3b;
  opacity: 0.8;
}

.contact-info .icon {
  margin-top: 40px;
}

.contact-info p,
.contact-info p a {
  text-align: unset;
  font: normal normal normal 16px/21px Poppins;
  color: #000000;
  opacity: 0.8;
}

.contact-info .icon:before,
.contact-info .icon:after {
  display: inline-block;
  content: "";
  border-top: 1px solid #0064b1;
  width: 48px;
  transform: translateY(-0.6rem);
}

.contact-form {
  padding: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #53749a29;
}

.contact-form .react-tel-input .form-control {
  width: 100%;
  height: 45px;
  margin-top: 30px !important;
}

.contact-form h2 {
  margin-top: 14px;
  padding-bottom: 20px;
}

.contact-form .form-control,
.contact-form button {
  margin-top: 35px;
}

.contact-form .MuiInputLabel-shrink {
  padding-left: 7px !important;
  padding-right: 7px !important;
  background-color: #fff !important;
  color: #03abee !important;
}

.contact-form .MuiButton-root {
  font-size: 14px;
  border: none;
  padding: 10px 20px !important;
  background-color: #0064b1;
  border-radius: 6px;
  color: #fff !important;
}

.contact-form .MuiButton-root:hover {
  background-color: transparent;
  color: #0064b1 !important;
  border: 1px solid #0064b1;
}

.machinery {
  margin-bottom: 60px;
}

.slick-slide .machinery img {
  display: unset !important;
}

.machinery .count h4 span {
  font-family: Impact;
  font-size: 3rem;
  color: #c4d9ec;
}

.machinery .count h4 {
  font-weight: 600;
  font-size: 1.1rem;
  color: #131d3b;
  opacity: 0.9;
}

.pri-policy {
  margin-top: 50px;
  margin-bottom: 50px;
}

.sitemap ul {
  list-style: none;
  padding-left: unset;
}

.sitemap ul li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.sitemap ul li a {
  color: #000000;
}

.sitemap ul li a:hover {
  color: #ffcc2f;
}

.gallery .MuiTab-root {
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  opacity: 0.8;
}

.gallery .MuiTab-root.Mui-selected {
  color: #000000;
}

.gallery .MuiTabs-indicator {
  background-color: #ffcc2f;
}

.gallery p {
  opacity: 1;
}

.gallery img {
  margin-bottom: 25px;
}

.info h4 span {
  font-family: Impact;
  font-size: 16rem;
  color: #ffcc2f;
}

.info h4 {
  margin-top: -150px;
  margin-left: -56px;
  font-weight: 700;
  font-size: 1.3rem;
  color: #131d3b;
  opacity: 0.8;
}

.client img {
  filter: grayscale();
  opacity: 0.8;
}

.client:hover img {
  filter: unset;
  opacity: 1;
}

.menu-footer {
  position: fixed;
  font-size: 13px;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffffd1;
  z-index: 999;
  border-top: 1px solid #ccc;
}

.menu-footer a {
  color: #374957 !important;
}

.menu-footer a.active {
  color: #03abed !important;
}

.menu-footer .b-right {
  border-right: 1px solid #ccc;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .row-cols-sm-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }
  .row-cols-sm-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-sm-9 > * {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }
  .row-cols-sm-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-sm-11 > * {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }
  .row-cols-sm-12 > * {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .row-cols-md-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }
  .row-cols-md-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-md-9 > * {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }
  .row-cols-md-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-md-11 > * {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }
  .row-cols-md-12 > * {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .row-cols-lg-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }
  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-lg-9 > * {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }
  .row-cols-lg-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-lg-11 > * {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }
  .row-cols-lg-12 > * {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }
  .row-cols-xl-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-xl-9 > * {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }
  .row-cols-xl-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-xl-11 > * {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }
  .row-cols-xl-12 > * {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .row-cols-xxl-7 > * {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }
  .row-cols-xxl-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
  .row-cols-xxl-9 > * {
    flex: 0 0 auto;
    width: 11.1111111111%;
  }
  .row-cols-xxl-10 > * {
    flex: 0 0 auto;
    width: 10%;
  }
  .row-cols-xxl-11 > * {
    flex: 0 0 auto;
    width: 9.0909090909%;
  }
  .row-cols-xxl-12 > * {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.align-items-center {
  align-items: center !important;
}
